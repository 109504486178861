package com.matprisguru.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.*

@Composable
fun GenericBox(text: String, isMaxWidth: Boolean = false, borderSize: CSSSizeValue<CSSUnit.px> = 0.px){
    var modifier = Modifier
        .borderRadius(12.px)
        .boxShadow(blurRadius = 1.px, spreadRadius = 1.px)
        .border(borderSize)
        .padding(24.px)
    if(isMaxWidth){
        modifier = modifier.fillMaxWidth()
    }
    Box(
        modifier = modifier
        ,contentAlignment = Alignment.Center
    ){
        SpanText(
            modifier = Modifier.fontSize(FontSize.Small),
            text = text
        )
    }
}

@Composable
fun DescriptionText(text: String){
    SpanText(text,
        modifier = Modifier.lineHeight(1.5).fontSize(1.0.cssRem).textAlign(TextAlign.Center)
            .margin(bottom = 28.px)
    )
}