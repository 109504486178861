package com.matprisguru.data

import com.matprisguru.model.ApiListResponse
import com.matprisguru.model.FileModel
import com.matprisguru.utility.fileToByteArray
import com.varabyte.kobweb.browser.api
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Uint8Array
import org.khronos.webgl.get
import org.w3c.files.File
import org.w3c.files.FileReader
import kotlin.js.Promise

/*
suspend fun addProduct(product: Product): String {
    return window.api.tryPost(
        apiPath = "addProduct",
        body = Json.encodeToString(product).encodeToByteArray()
    )?.decodeToString().toString()
}
*/

suspend fun getAllProducts(
    onSuccess: (ApiListResponse) -> Unit,
    onError: (Exception) -> Unit
){
    try {
        val result = window.api.tryGet(
            apiPath = "getallproducts"
        )?.decodeToString()

        //println("getAllProducts" + result + (result==null))
        if(result != null){
            onSuccess(Json.decodeFromString(result))
        }else{
            onError(Exception("Something went wrong, Flavio"))
        }
    }catch (e: Exception){
        println(e)
        onError(e)
    }
}

suspend fun uploadFileToOCRSpace(file: File): String {
    val fileByteArray = fileToByteArray(file)

    println("body: ${fileByteArray.size}")

    val fileModel = FileModel(
        fileByteArray = fileByteArray,
        fileName = file.name
    )
    val body = Json.encodeToString(fileModel).encodeToByteArray()

    val result = window.api.tryPost(
        apiPath = "preprocessreceipt"
        ,body = body
    )

    return result?.decodeToString().toString()
}


suspend fun finalUploadReceipt(file: File): String {
    val fileByteArray = fileToByteArray(file)

    println("body: ${fileByteArray.size}")

    val fileModel = FileModel(
        fileByteArray = fileByteArray,
        fileName = file.name
    )
    val body = Json.encodeToString(fileModel).encodeToByteArray()

    // Send FormData using fetch API
    return window.api.tryPost(
        apiPath = "finalprocessreceipt"
        ,body = body
    )?.decodeToString().toString()
}

suspend fun deleteProduct(
    productDescription: String
    /*,
    onSuccess: (ApiListResponse) -> Unit,
    onError: (Exception) -> Unit*/
){

    val body = Json.encodeToString(productDescription).encodeToByteArray()

    try {
        val result = window.api.tryPost(
            apiPath = "deleteproduct",
            body = body
        )?.decodeToString()

        /*
        if(result != null){
            onSuccess(Json.decodeFromString(result))
        }else{
            onError(Exception("Something went wrong in deletion of product $productDescription"))
        }

         */
    }catch (e: Exception){
        println(e)
        //onError(e)
    }
}