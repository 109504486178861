package com.matprisguru.model

import kotlinx.serialization.Serializable

@Serializable
data class Product(
    val store: String,
    val description: String,
    val price: Double,
    val date: String,
    val imageUrl: String
)