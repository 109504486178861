package com.matprisguru.pages

import androidx.compose.runtime.*
import com.matprisguru.components.*
import com.matprisguru.components.widgets.GradientBox
import com.matprisguru.data.getAllImageObjects
import com.matprisguru.data.getAllMasterProducts
import com.matprisguru.data.getAllProducts
import com.matprisguru.model.*
import com.matprisguru.navigation.Screen
import com.matprisguru.utility.GlobalVariables
import com.matprisguru.utility.GlobalVariables.isMobile
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.H1

val isAdminMode = false

@Page
@Composable
fun HomePage() {

    val context = rememberPageContext()

    var response by remember { mutableStateOf<ApiListResponse>(ApiListResponse.Loading) }
    var masterResponse by remember { mutableStateOf<ApiMasterListResponse>(ApiMasterListResponse.Loading) }
    var imageResponse by remember { mutableStateOf<ApiImageListResponse>(ApiImageListResponse.Loading) }

    val searchTextCommon = remember { mutableStateOf("") }

    LaunchedEffect(Unit){

        getAllProducts(
            onSuccess = {
                if(it is ApiListResponse.Success){
                    response = it
                }
            },
            onError = {
                println("Error in getting products")
                println(it.message.toString())
                response = ApiListResponse.Error(it.message.toString())
            }
        )


    }

    LaunchedEffect(Unit){
        getAllMasterProducts(
            onSuccess = {
                if(it is ApiMasterListResponse.Success){
                    masterResponse = it
                }
            },
            onError = {
                println("Error in getting MASTER products")
                println(it.message.toString())
                masterResponse = ApiMasterListResponse.Error(it.message.toString())
            }
        )
    }

    LaunchedEffect(Unit){
        getAllImageObjects(
            onSuccess = {
                if(it is ApiImageListResponse.Success){
                    imageResponse = it
                }
            },
            onError = {
                println("Error in getting IMAGE products")
                println(it.message.toString())
                imageResponse = ApiImageListResponse.Error(it.message.toString())
            }
        )
    }


    HeaderLayout(
        context = context
    ){
        GradientBox(
            contentAlignment = Alignment.Center,
            modifier = Modifier.fillMaxWidth()
        ) {
            Column(
                modifier = Modifier.fillMaxHeight().fillMaxWidth(90.percent).maxWidth(1000.px).padding(4.px),
                horizontalAlignment = Alignment.CenterHorizontally,

                //verticalArrangement = Arrangement.Top
            ) {

                H1(
                    attrs = {
                        style {
                            marginBottom(8.px)
                            marginTop(64.px)
                            marginBottom(24.px)
                        }
                    }
                ) {
                    SpanText(
                        "Sjekk priser på daglivarer",
                        modifier = Modifier.textAlign(TextAlign.Center)
                        //.textShadow(1.px,3.px,color=Color.red)
                    )
                }

                SpanText(
                    "Sammenlign priser fra Rema 1000, Kiwi " +
                            "og Extra og se hvor produktene dine er billigst.",
                    modifier = Modifier.lineHeight(1.5).fontSize(1.25.cssRem).textAlign(TextAlign.Center)
                        .margin(bottom = 28.px)
                )

                LotteryButton()

                QualityAlertBox()

                SearchElement(
                    searchTextCommon, fieldPlaceholder = "Søk et produkt...",
                    fieldHeight = searchFieldHeight * 1.5
                )



            }
        }

        Column(
            modifier = Modifier.fillMaxSize(90.percent).maxWidth(1000.px).padding(4.px),
            horizontalAlignment = Alignment.CenterHorizontally,

            //verticalArrangement = Arrangement.Top
        ) {


            if(
                response is ApiListResponse.Loading ||
                masterResponse is ApiMasterListResponse.Loading ||
                imageResponse is ApiImageListResponse.Loading
                ){
                Image("apples.gif", modifier = Modifier.align(Alignment.CenterHorizontally).margin(20.px))
            }
            else{
                MainTable(response, masterResponse, imageResponse, searchTextCommon)
            }


        }

    }


}


@Composable
fun MainTable(
    response: ApiListResponse,
    masterResponse: ApiMasterListResponse,
    imageResponse: ApiImageListResponse,
    searchTextCommon: MutableState<String>) {

    //StoreNameHeader()
    val (filteredProducts, filteredMasterProducts) = filterProductsUsingGroups(response, masterResponse, searchTextCommon.value)
    //val filteredProductsWithImages = addImagestoFilteredProducts(filteredProducts, imageResponse)
    val filteredMasterProductsWithImages = addImagestoFilteredMasterItems(filteredMasterProducts, imageResponse)
    AllGroupBoxes(filteredProducts, filteredMasterProductsWithImages)

/*
    Row(
        modifier = Modifier
//            .fillMaxHeight()
            .fillMaxWidth()
            .margin(top=6.px),
        horizontalArrangement = Arrangement.Center
    ){
        StoreColumn(filteredProductRema)
        StoreColumn(filteredProductKiwi)
        StoreColumn(filteredProductExtra)
    }

 */

}

fun filterProductsUsingGroups(
    response: ApiListResponse,
    masterResponse: ApiMasterListResponse,
    searchText: String
): Pair<List<Product>?, List<MasterProduct>?> {
    //val maxNumberOfProductsPerColumn = if(isAdminMode) 99999 else 20
    //.take(maxNumberOfProductsPerColumn)

    if(
        response is ApiListResponse.Success &&
        masterResponse is ApiMasterListResponse.Success
    ){
        if(searchText==""){
            return Pair(response.data, masterResponse.data)

        }
        else{
            val filteredProductDataPrep = response.data.filter {
                it.description.contains(searchText, ignoreCase = true)
            }
            val filteredMasterData = masterResponse.data.filter{
                it.masterDescription in filteredProductDataPrep.map { it.masterDescription }
            }
            val filteredProductData = response.data.filter{
                it.masterDescription in filteredMasterData.map{ it.masterDescription }
            }
            return Pair(filteredProductData, filteredMasterData)
        }

    }
    else{
        println("Nothing to display")
        return Pair(null, null)
    }

}

fun addImagestoFilteredMasterItems(
    filteredMasterProducts: List<MasterProduct>?, imageResponse: ApiImageListResponse
) : List<MasterItemWithImage?>?
{

    if(imageResponse is ApiImageListResponse.Success){
        val imageMap = imageResponse.data.associateBy { it.description }
        val productsWithImages = filteredMasterProducts?.map { product ->
            imageMap[product.masterDescriptionEnglish]?.let { image ->
                MasterItemWithImage(product, image)
            }
        }
        return productsWithImages
    }
    return null
}

fun addImagestoFilteredProducts(
    filteredProducts: List<Product>?, imageResponse: ApiImageListResponse
) : List<ProductWithImage?>?
{

    if(imageResponse is ApiImageListResponse.Success){
        val imageMap = imageResponse.data.associateBy { it.description }
        val productsWithImages = filteredProducts?.map { product ->
            imageMap[product.masterDescription]?.let { image ->
                ProductWithImage(product, image)
            }
        }
        return productsWithImages
    }
    return null
}

/*1: rema, 2: kiwi, 3: extra*/
@Composable
fun AllGroupBoxes(
    filteredProducts: List<Product>?,
    filteredMasterProducts: List<MasterItemWithImage?>?
) {
    if (filteredProducts != null && filteredMasterProducts != null) {
        // Sort master products by countDistinct and countTotal in descending order
        val sortedMasterDescriptions = filteredMasterProducts
            .sortedWith(compareByDescending<MasterItemWithImage?> { it?.masterItem?.countDistinct }
                .thenByDescending { it?.masterItem?.countTotal })
            .map { it?.masterItem?.masterDescription }
            .take(20)

        // Sort filteredProducts based on the master description order
        val sortedFilteredProducts = filteredProducts
            .filter { (it.masterDescription) in sortedMasterDescriptions }
            .sortedBy {
            sortedMasterDescriptions.indexOf(it.masterDescription)
        }

        // Group by master description and display
        sortedFilteredProducts.map { it.masterDescription }
            .distinct()
            .forEach { masterDesc ->
                val groupProducts = sortedFilteredProducts.filter { it.masterDescription == masterDesc }
                val masterItem = filteredMasterProducts.first{ it?.masterItem?.masterDescription == masterDesc }
                ProductGroupBox(groupProducts, masterItem)
            }
    } else {
        SpanText("Ingen data.")
    }
}

@Composable
fun StoreName(imageSrc: String){
    Box(
        modifier = Modifier
            .fillMaxWidth().height(40.px).border(2.px)
            .margin(bottom=15.px),
        contentAlignment = Alignment.Center
    ) {
        Image(
            imageSrc,
            modifier = Modifier
                .fillMaxHeight()  // Fill the entire box (both width and height)
                .objectFit(ObjectFit.Cover)  // Ensures the image covers the box while maintaining aspect ratio
        )
        //SpanText()
    }
}

@Composable
fun LotteryButton(){
    val context = rememberPageContext()



    Button(
        onClick = { context.router.navigateTo(Screen.ContributePage.route) },
        Modifier.padding(8.px).minWidth(50.percent).backgroundColor(Color.green)
    ){
        SpanText("Gratis Lotteri", Modifier.color(Color.white).fontSize(FontSize.Large))
    }

}