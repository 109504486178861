package com.matprisguru.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonContentPolymorphicSerializer
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonObject

@Serializable(MasterListResponseSerializer::class)
sealed class ApiMasterListResponse {

    @Serializable
    @SerialName("loading")
    object Loading: ApiMasterListResponse()

    @Serializable
    @SerialName("success")
    data class Success(val data: List<MasterProduct>): ApiMasterListResponse()

    @Serializable
    @SerialName("error")
    data class Error(val message: String): ApiMasterListResponse()
}

object MasterListResponseSerializer: JsonContentPolymorphicSerializer<ApiMasterListResponse>(ApiMasterListResponse::class){
    override fun selectDeserializer(element: JsonElement) = when {
        "data" in element.jsonObject -> ApiMasterListResponse.Success.serializer()
        "message" in element.jsonObject -> ApiMasterListResponse.Error.serializer()
        else -> ApiMasterListResponse.Loading.serializer()
    }

}