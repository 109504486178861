package com.matprisguru.pages

import androidx.compose.runtime.*
import androidx.compose.web.events.SyntheticDragEvent
import com.matprisguru.components.DescriptionText
import com.matprisguru.components.HeaderLayout
import com.matprisguru.components.widgets.GradientBox
import com.matprisguru.data.finalUploadReceipt
import com.matprisguru.utility.preProcessReceipt
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaCheck
import com.varabyte.kobweb.silk.components.icons.fa.FaUpload
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLInputElement
import org.w3c.files.File
import org.w3c.files.get

@Page
@Composable
fun ContributePage() {
    val context = rememberPageContext()
    val scope = rememberCoroutineScope()

    HeaderLayout(
        context = context
    ){
        var selectedFile by remember { mutableStateOf<File?>(null) }
        var selectedFileName by remember { mutableStateOf<String?>(null) }
        var isValidating by remember { mutableStateOf(false) }
        var isUploading by remember { mutableStateOf(false) }
        var showThanks by remember { mutableStateOf(false) }
        var fileInputRef by remember { mutableStateOf<HTMLInputElement?>(null) }
        var isDragging by mutableStateOf(false)

        GradientBox(
            contentAlignment = Alignment.Center,
            modifier = Modifier.fillMaxWidth()
        ) {
            Column(
                modifier = Modifier.fillMaxSize()
                    .padding(top = 20.px),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                H2(
                    attrs = {
                        style {
                            marginTop(32.px)
                            marginBottom(40.px)
                        }
                    }
                ) { Text("Bidra") }

                Column(
                    horizontalAlignment = Alignment.CenterHorizontally,
                    modifier = Modifier.fillMaxWidth(70.percent)
                ) { //to make sure the second box with maxWidth gets the same width as the first box
//                GenericBox("Ønsker du se også dine egne produkter på Matprisguru? " +
//                        "Nettsiden lever av opplastede kvitteringer. " +
//                        "Last opp din Rema, Kiwi eller Extra kvittering og bidra til " +
//                        "projektet.",
//                    true)
                    DescriptionText(
                        "Ønsker du se også dine egne produkter på Matprisguru? " +
                                "Nettsiden lever av opplastede kvitteringer. " +
                                "Last opp din Rema, Kiwi eller Extra kvittering og bidra til " +
                                "projektet."
                    )
                }
            }
        }

        Column(
            modifier = Modifier.fillMaxSize()
                .padding(top=20.px),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                modifier = Modifier.fillMaxWidth(70.percent)
            ) {


                val dragBoxModifier0 = Modifier.fillMaxWidth()
                    .borderRadius(7.px)
                    .padding(20.px)
                    .border(1.px, LineStyle.Dashed)
                    .cursor(Cursor.Pointer)
                    .margin(top = 20.px)
                    .onClick {
                        fileInputRef?.click()
                    }
                    .onDragOver { event: SyntheticDragEvent ->
                        event.preventDefault() // Prevent default behavior to allow drop
                        isDragging = true
                    }
                    .onDragLeave {
                        isDragging = false
                    }
                    .onDrop { event: SyntheticDragEvent ->
                        event.preventDefault()
                        isDragging = false

                        // Handle the dropped file(s)
                        val droppedFile = event.dataTransfer?.files?.item(0)
                        if (droppedFile != null) {
                            selectedFile = droppedFile
                            selectedFileName = droppedFile.name
                        }
                    }

                val dragBoxModifier = if (isDragging) {
                    dragBoxModifier0.backgroundColor(Color.lightgray)
                } else {
                    dragBoxModifier0
                }


                Box(
                    modifier = dragBoxModifier, contentAlignment = Alignment.Center
                ) {
                    Input(
                        type = InputType.File,
                        attrs = {
                            style {
                                display(DisplayStyle.None) // Hide the input
                            }
                            onInput {
                                selectedFile = it.target.files?.get(0)
                                selectedFileName = selectedFile?.name
                            }
                            ref { inputElement ->
                                fileInputRef = inputElement
                                onDispose { fileInputRef = null }
                            }
                        }
                    )
                    Row(
                        modifier = Modifier.alignContent(AlignContent.Center),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        FaUpload(
                            modifier = Modifier
                                .margin(20.px), size = IconSize.XXL
                        )
                        SpanText(
                            "Drag & Drop",
                            modifier = Modifier
                        )
                    }


                }

                //display the file name
                selectedFileName?.let { fileName ->
                    SpanText(
                        fileName, modifier = Modifier

                    )
                }


                if (isValidating) {
                    UploadInfoBox(text = "Validerer...")
                } else if (isUploading) {
                    UploadInfoBox()
                } else if (showThanks) {

                    ThanksBox()

                    LaunchedEffect(Unit) {
                        delay(5 * 1000)
                        showThanks = false
                    }
                }

                Box(
                    modifier = Modifier.fillMaxWidth()
                        .padding(20.px),
                    contentAlignment = Alignment.Center
                ) {
                    Button(
                        attrs = {
                            style {
                                height(60.px)
                                width(240.px)
                                cursor(Cursor.Pointer.toString())
                            }
                            onClick {
                                if (!isUploading) {
                                    if (selectedFileName != null) {
                                        scope.launch {
//                                        try {
//                                            val responseString = finalUploadReceipt(selectedFile!!)
//                                            println(responseString)
//                                            showThanks = true
//                                        } catch (e: Exception){
//                                            window.alert("Noe gikk galt.")
//                                        }


                                            //saveReducedFile(resizedFile!!)
                                            isValidating = true
                                            println("selected file: ${selectedFile!!.name}")
                                            val preprocessResponse = preProcessReceipt(selectedFile!!)
                                            isValidating = false
                                            if (preprocessResponse.isValidReceipt) {
                                                isUploading = true
                                                try {
                                                    val responseString = finalUploadReceipt(selectedFile!!)
                                                    println(responseString)
                                                    showThanks = true

                                                    //window.alert("Takk for ditt bidrag")
                                                } catch (e: Exception) {
                                                    window.alert("Noe gikk galt.")
                                                }


                                            } else if (preprocessResponse.isSuccessful && !preprocessResponse.isValidReceipt) {
                                                window.alert("Det ser ikke ut som en kvittering.")
                                            } else {
                                                window.alert("Noe gikk galt med opplastingen: ${preprocessResponse.errorMessage}")
                                            }


                                            isUploading = false

                                            selectedFile = null
                                            selectedFileName = null

                                        }


                                    } else {
                                        window.alert("No file selected!")
                                    }
                                }

                            }
                        }
                    ) {
                        Text("Last opp")
                    }
                }

            }

        }
    }
}

@Composable
fun UploadInfoBox(text: String = "Laster opp..."){
    Box(
        modifier = Modifier
            .borderRadius(7.px)
            .height(60.px)
            .margin(20.px)
            .boxShadow(blurRadius = 4.px, spreadRadius = 1.px)
            .border(1.px)
            .padding(16.px)
    ){
        Row(
            modifier = Modifier.fillMaxSize(),
            verticalAlignment = Alignment.CenterVertically
        ){
            SpanText(text, modifier = Modifier.fillMaxWidth())
        }

    }
}


@Composable
fun ThanksBox(){
    Box(
        modifier = Modifier
            .borderRadius(7.px)
            .height(60.px)
            .margin(20.px)
            .boxShadow(blurRadius = 4.px, spreadRadius = 1.px)
            .border(1.px)
            .padding(6.px)
    ){
        Row(
            modifier = Modifier.fillMaxSize().alignContent(AlignContent.Center),
            verticalAlignment = Alignment.CenterVertically
        ){
            FaCheck(modifier = Modifier.color(Color.white).margin(right = 20.px))
            SpanText("Takk for ditt bidrag", modifier = Modifier.fillMaxWidth().color(Color.white))
        }

    }
}

/*
suspend fun saveReducedFile(resizedFile: File){
    if (js("window.showSaveFilePicker") != undefined) {
        try {
            val a = fileToByteArray(resizedFile!!)
            val blob = Blob(arrayOf(a), BlobPropertyBag("application/octet-stream"))

            // Use the File System Access API to show the save file picker
            val fileHandle = js("window.showSaveFilePicker({suggestedName: 'reduced.jpg'})").unsafeCast<Promise<dynamic>>().await()

            // Create a writable stream using the File System Access API
            val writableStream = fileHandle.createWritable().unsafeCast<Promise<dynamic>>().await()

            // Write the Blob (file) to the writable stream
            writableStream.write(blob).unsafeCast<Promise<dynamic>>().await()

            // Close the writable stream
            writableStream.close().unsafeCast<Promise<dynamic>>().await()

            window.alert("File saved successfully")
        } catch (e: Exception) {
            window.alert("File save failed: ${e.message}")
        }
    } else {
        window.alert("File System Access API is not supported in your browser")
    }
}*/
