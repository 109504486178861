package com.matprisguru.pages

import androidx.compose.runtime.Composable
import com.matprisguru.components.DescriptionText
import com.matprisguru.components.GenericBox
import com.matprisguru.components.HeaderLayout
import com.matprisguru.components.widgets.GradientBox
import com.matprisguru.navigation.Screen
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Text


@Page
@Composable
fun AboutPage(){
    val context = rememberPageContext()

    HeaderLayout(context){
        GradientBox(
            contentAlignment = Alignment.Center,
            modifier = Modifier.fillMaxWidth()
        ) {
            Column(
                modifier = Modifier.fillMaxHeight().fillMaxWidth(60.percent).padding(4.px)
                    .onClick {
                        context.router.navigateTo(Screen.HomePage.route)
                    }
                    .alignContent(AlignContent.Center),
                horizontalAlignment = Alignment.CenterHorizontally
            ){
                H2(attrs = {style {
                    marginTop(32.px)
                    marginBottom(40.px)
                }}
                ) { Text("Rema vs Kiwi vs Extra") }

//            GenericBox(
//                "Jeg sammenligner vanlige priser (uten rabatt) fra butikkene Rema 1000, Kiwi " +
//                        "og Coop Extra. Her kan du se prisforskjellene mellom disse kjedene " +
//                        "og finne ut hvem som er billigst. " +
//                        "Sammenlign matvarepriser for å gjøre de beste valgene når du handler!",
//                true
//                )
                DescriptionText("Jeg sammenligner vanlige priser (uten rabatt) fra butikkene Rema 1000, Kiwi " +
                        "og Coop Extra. Her kan du se prisforskjellene mellom disse kjedene " +
                        "og finne ut hvem som er billigst. " +
                        "Sammenlign matvarepriser for å gjøre de beste valgene når du handler!")

                H3(attrs = {style {
                    marginBottom(10.px)
                }}
                ) { Text("Datakvalitet") }
//            GenericBox(
//                "Produktenes navn og priser blir maskinelt lest fra mine " +
//                        "personlige kvitteringer. Kvaliteten kan derfor variere. " +
//                        "Prisdataene er kun så oppdaterte som mine opplastede kvitteringer. " +
//                        "Nyere data markeres med kalenderikoner i grønne farger, " +
//                        "mens eldre data vises med oransje eller til og med røde farger.",
//                true
//            )
                DescriptionText("Produktenes navn og priser blir maskinelt lest fra mine " +
                        "personlige kvitteringer. Kvaliteten kan derfor variere. " +
                        "Prisdataene er kun så oppdaterte som mine opplastede kvitteringer. " +
                        "Nyere data markeres med kalenderikoner i grønne farger, " +
                        "mens eldre data vises med oransje eller til og med røde farger.")

            }
        }

    }
}