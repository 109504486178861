package com.matprisguru.data

import com.matprisguru.model.*
import com.matprisguru.utility.fileToByteArray
import com.varabyte.kobweb.browser.api
import kotlinx.browser.window
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.files.File

/*
suspend fun addProduct(product: Product): String {
    return window.api.tryPost(
        apiPath = "addProduct",
        body = Json.encodeToString(product).encodeToByteArray()
    )?.decodeToString().toString()
}
 */

suspend fun addNickname(
    nickname: Nickname,
    //onSuccess: (ApiListResponse) -> Unit,
    onError: (Exception) -> Unit
) {

    try {
        window.api.tryPost(
            apiPath = "addnickname",
            body = Json.encodeToString(nickname).encodeToByteArray()
        )?.decodeToString().toString()
    } catch (e:Exception){
        onError(e)
    }

}

suspend fun getAllProducts(
    onSuccess: (ApiListResponse) -> Unit,
    onError: (Exception) -> Unit
){
    try {
        val result = window.api.tryGet(
            apiPath = "getallproducts"
        )?.decodeToString()

        //println("getAllProducts" + result + (result==null))
        if(result != null){
            onSuccess(Json.decodeFromString(result))
        }else{
            onError(Exception("Something went wrong, Flavio"))
        }
    }catch (e: Exception){
        println(e)
        onError(e)
    }
}

suspend fun getAllMasterProducts(
    onSuccess: (ApiMasterListResponse) -> Unit,
    onError: (Exception) -> Unit
){
    try {
        val result = window.api.tryGet(
            apiPath = "getallmasterproducts"
        )?.decodeToString()

        if(result != null){
            onSuccess(Json.decodeFromString(result))
        }else{
            onError(Exception("Something went wrong in getting master products in apiCalls.kt"))
        }
    }catch (e: Exception){
        println(e)
        onError(e)
    }
}

suspend fun getAllImageObjects(
    onSuccess: (ApiImageListResponse) -> Unit,
    onError: (Exception) -> Unit
){
    try {
        val result = window.api.tryGet(
            apiPath = "getallimageobjects"
        )?.decodeToString()

        if(result != null){
            onSuccess(Json.decodeFromString(result))
        }else{
            onError(Exception("Something went wrong in getting images in apiCalls.kt"))
        }
    }catch (e: Exception){
        println(e)
        onError(e)
    }
}

suspend fun getAllWinningChances(
    onSuccess: (ApiChancesListResponse) -> Unit,
    onError: (Exception) -> Unit
){
    try {
        val result = window.api.tryGet(
            apiPath = "getwinningchances"
        )?.decodeToString()

        if(result != null){
            onSuccess(Json.decodeFromString(result))
        }else{
            onError(Exception("Something went wrong in getallwinningchances"))
        }
    }catch (e: Exception){
        println("exception in winning chances: $e")
        onError(e)
    }
}

suspend fun getAllNicknames(
    onSuccess: (ApiNicknameListResponse) -> Unit,
    onError: (Exception) -> Unit
){
    try {
        val result = window.api.tryGet(
            apiPath = "getnicknames"
        )?.decodeToString()

        if(result != null){
            onSuccess(Json.decodeFromString(result))
        }else{
            onError(Exception("Something went wrong in getnicknames"))
        }
    }catch (e: Exception){
        println("exception in nicknames: $e")
        onError(e)
    }
}

suspend fun uploadFileToOCRSpace(file: File): String {
    val fileByteArray = fileToByteArray(file)

    println("body: ${fileByteArray.size}")

    val fileModel = FileModel(
        fileByteArray = fileByteArray,
        fileName = file.name
    )
    val body = Json.encodeToString(fileModel).encodeToByteArray()

    val result = window.api.tryPost(
        apiPath = "preprocessreceipt"
        ,body = body
    )
    println("api call result: $result, ${result?.decodeToString()}")


    return result?.decodeToString().toString()
}

suspend fun uploadToDrive(file: File, fileName: String): String {
    val fileByteArray = fileToByteArray(file)

    //println("body: ${fileByteArray.size}")

    val fileModel = FileModel(
        fileByteArray = fileByteArray,
        fileName = file.name,
        newFileName = fileName
    )
    val body = Json.encodeToString(fileModel).encodeToByteArray()

    // Send FormData using fetch API
    return window.api.tryPost(
        apiPath = "uploadToDrive"
        ,body = body
    )?.decodeToString().toString()
}


suspend fun finalUploadReceipt(file: File): String {
    val fileByteArray = fileToByteArray(file)

    println("body: ${fileByteArray.size}")

    val fileModel = FileModel(
        fileByteArray = fileByteArray,
        fileName = file.name
    )
    val body = Json.encodeToString(fileModel).encodeToByteArray()

    // Send FormData using fetch API
    return window.api.tryPost(
        apiPath = "finalprocessreceipt"
        ,body = body
    )?.decodeToString().toString()
}

suspend fun deleteProduct(
    productDescription: String
    /*,
    onSuccess: (ApiListResponse) -> Unit,
    onError: (Exception) -> Unit*/
){

    val body = Json.encodeToString(productDescription).encodeToByteArray()

    try {
        val result = window.api.tryPost(
            apiPath = "deleteproduct",
            body = body
        )?.decodeToString()

        /*
        if(result != null){
            onSuccess(Json.decodeFromString(result))
        }else{
            onError(Exception("Something went wrong in deletion of product $productDescription"))
        }

         */
    }catch (e: Exception){
        println(e)
        //onError(e)
    }
}