package com.matprisguru.model

import kotlinx.serialization.Serializable

@Serializable
data class Product(
    val store: String,
    val description: String,
    val price: Double,
    val date: String,
    val masterDescription: String
)

@Serializable
data class MasterProduct(
    val masterDescription: String,
    val masterDescriptionEnglish: String,
    val count1: Int,
    val count2: Int,
    val count3: Int,
    val countDistinct: Int,
    val countTotal: Int
)

@Serializable
data class ImageObject(
    val description: String,
    val imageUrl: String
)


data class ProductWithImage(
    val product: Product,
    val image: ImageObject
)

data class MasterItemWithImage(
    val masterItem: MasterProduct,
    val image: ImageObject
)

@Serializable
data class WinningChance(
    val _id: String,
    val products: Int
)

@Serializable
data class Nickname(
    val _id: String,
    val nickname: String
)

@Serializable
data class CombinedData(
    val _id: String,
    val nickname: String,
    val products: Int
)