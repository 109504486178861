package com.matprisguru.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonContentPolymorphicSerializer
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonObject

@Serializable(ImageListResponseSerializer::class)
sealed class ApiImageListResponse {

    @Serializable
    @SerialName("loading")
    object Loading: ApiImageListResponse()

    @Serializable
    @SerialName("success")
    data class Success(val data: List<ImageObject>): ApiImageListResponse()

    @Serializable
    @SerialName("error")
    data class Error(val message: String): ApiImageListResponse()
}

object ImageListResponseSerializer: JsonContentPolymorphicSerializer<ApiImageListResponse>(ApiImageListResponse::class){
    override fun selectDeserializer(element: JsonElement) = when {
        "data" in element.jsonObject -> ApiImageListResponse.Success.serializer()
        "message" in element.jsonObject -> ApiImageListResponse.Error.serializer()
        else -> ApiImageListResponse.Loading.serializer()
    }

}