package com.matprisguru.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.FaMagnifyingGlass
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Input

val searchFieldHeight = 32.px

@Composable

fun SearchElement(
    searchText: MutableState<String>, fieldPlaceholder: String,
    fieldHeight: CSSSizeValue<CSSUnit.px> = searchFieldHeight
){


    Column(
        modifier = Modifier.fillMaxWidth().margin(bottom = 34.px)
    ){
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .height(50.px)
                .border(1.px, LineStyle.Solid, Color.black)
                .borderRadius(7.px)
                .margin(bottom = 8.px)
            //.boxShadow(blurRadius = 4.px, spreadRadius = 1.px),

        ){
            Row(
                modifier = Modifier.fillMaxSize(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ){
                Box(
                    modifier = Modifier
                        .fillMaxHeight()
                        .width(50.px)
                        .borderRadius(topLeft = 7.px, bottomLeft = 7.px)
                        .backgroundColor(Color.green)
                        .padding(8.px),
                    contentAlignment = Alignment.Center
                ){
                    FaMagnifyingGlass(
                        modifier = Modifier.color(Color.white)
                            .alignContent(com.varabyte.kobweb.compose.css.AlignContent.Center)
                    )
                }

                Input(
                    type = InputType.Search,
                    attrs = {
                        value(searchText.value)
                        onInput { event -> searchText.value = event.value }
                        placeholder(fieldPlaceholder)
                        style {
                            padding(8.px)                    // Add padding to make it a bit larger
                            fontSize(16.px)
                            height(Height.Inherit)
                            width(Width.Inherit)
                            border(0.px)
                            outline("none")
                        }
                    }
                )

                Box(
                    modifier = Modifier
                        .fillMaxHeight()
                        .borderRadius(topRight = 7.px, bottomRight = 7.px)
                        .backgroundColor(Color.gray)
                        .padding(16.px),
                    contentAlignment = Alignment.Center
                ){
                    SpanText("Oslo", Modifier.color(Color.white))
                }


            }
        }

        QuickButtons(searchText)
    }

}


@Composable
fun QuickButtons(searchText: MutableState<String>){
    Row(){
        QuickButton("Melk", searchText)
        QuickButton("Pepsi", searchText)
        QuickButton("Pizza", searchText)
    }
}

@OptIn(ExperimentalComposeWebApi::class, DelicateCoroutinesApi::class)
@Composable
fun QuickButton(description: String, searchText: MutableState<String>){
    val bgColor = remember { mutableStateOf(Color.white) }
    val shadowSize = remember { mutableStateOf(0.px) }
    Button(
        attrs = {
            style {
                border(1.px, LineStyle.Solid)
                boxShadow(blurRadius = 1.px, spreadRadius = shadowSize.value)
                borderRadius(22.px)
                cursor(Cursor.Pointer)
                backgroundColor(bgColor.value)
                padding(16.px)
                margin(topBottom = 12.px)
                marginRight(8.px)

            }
            onMouseOver {
                bgColor.value = rgb(240,240,240)
                shadowSize.value = 1.px
            }
            onMouseOut {
                bgColor.value = Color.white
                shadowSize.value = 0.px
            }
            onClick {
                searchText.value = description

                // Temporarily change the boxShadow and scale for "press down" effect
                shadowSize.value = 0.px
                style { transform { scale(0.90) } }

                // Reset back after a short delay to simulate release
                kotlinx.coroutines.GlobalScope.launch {
                    delay(100)  // Delay for effect duration
                    shadowSize.value = 1.px
                    style { transform { scale(1.0) } }
                }
            }
        }
    ) {
        SpanText(description)
    }
}