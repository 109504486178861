package com.matprisguru.components

import androidx.compose.runtime.*
import com.matprisguru.utility.isDateStringParsable
import com.varabyte.kobweb.compose.css.CSSPosition
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.datetime.*
import kotlinx.datetime.format.char
import kotlinx.datetime.internal.JSJoda.DateTimeFormatter
import kotlinx.datetime.internal.JSJoda.DateTimeParseException
import org.jetbrains.compose.web.css.*

@Composable
fun DateAgeBar(dateString: String){


        val daysToNow = priceAge(dateString)
        val barColor = priceAgeColor(daysToNow)

        val barRelLength: Double = when{
            daysToNow <= 90 -> 1.0
            daysToNow <= 180 -> 0.8
            daysToNow <= 360 -> 0.5
            else -> 0.1
        }

        Box(
            modifier = Modifier
                .fillMaxWidth((60*barRelLength).percent)
                .height(3.px)
                .background(barColor)

        )






}

fun priceAgeColor(priceAge: Int): CSSColorValue {

    val priceAgeColor =  when{
        priceAge <= 90 -> Color.green
        priceAge <= 180 -> Color.orange
        priceAge <= 360 -> Color.orangered
        else -> Color.red
    }

    return priceAgeColor
}

fun priceAge(dateString: String): Int{
    val givenDate = LocalDateTime.parse(dateString.replace(" ", "T"))
    val today = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).date
    val daysToNow = givenDate.date.daysUntil(today)

    return daysToNow
}

fun formatDate(dateString: String): String {
    val dateTime = LocalDateTime.parse(dateString.replace(" ", "T"))

    val customFormat = LocalDateTime.Format {
        dayOfMonth()  // Day with leading zero if needed
        char('.')
        monthNumber() // Month with leading zero if needed
        char('.')
        year()
    }

    // Format the LocalDateTime to the desired string format
    return dateTime.format(customFormat)
}