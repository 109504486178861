package com.matprisguru.utility

import kotlinx.coroutines.await
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.internal.JSJoda.DateTimeParseException
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Uint8Array
import org.khronos.webgl.get
import org.w3c.files.File
import org.w3c.files.FileReader
import kotlin.js.Promise

fun isDateStringParsable(dateString: String): Boolean {
    return try {
        LocalDateTime.parse(dateString)
        true
    } catch (e: DateTimeParseException) {
        false
    }
}

suspend fun fileToByteArray(file: File): ByteArray {
    // Create a FileReader
    val reader = FileReader()

    // Use a Promise to read the file as an ArrayBuffer
    val arrayBufferPromise = Promise { resolve, reject ->
        reader.onload = { resolve(reader.result as ArrayBuffer) }
        reader.onerror = { reject(Exception("File reading error")) }
        reader.readAsArrayBuffer(file)
    }

    // Wait for the ArrayBuffer to be ready
    val arrayBuffer = arrayBufferPromise.await()

    // Convert the ArrayBuffer to ByteArray
    val uint8Array = Uint8Array(arrayBuffer)
    val byteArray = ByteArray(uint8Array.length)

    for (i in 0 until uint8Array.length) {
        byteArray[i] = uint8Array[i]
    }

    return byteArray
}

