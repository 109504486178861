package com.matprisguru.utility

import kotlinx.browser.window

object GlobalVariables {
    val rema = "Rema 1000"
    val remaShort = "Rema"
    val kiwi = "Kiwi"
    val extra = "EXTRA"

    val listAllStoresShort = listOf(remaShort, kiwi, extra)

    val isMobile = window.innerWidth < 800
}

