package com.matprisguru.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.icons.fa.FaCartShopping
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun QualityAlertBox(){
    Box(
        Modifier.fillMaxWidth().maxWidth(300.px).maxWidth(60.percent)
            .then(genericBoxModifier)
            .margin(52.px)
        , contentAlignment = Alignment.Center
    ) {
        Column(
            Modifier.fillMaxWidth()
                .padding(12.px)
                .margin(12.px)
            , horizontalAlignment = Alignment.CenterHorizontally
        ){
            FaCartShopping(Modifier.margin(24.px), size=IconSize.XXL)
            SpanText(
                "All data kommer fra skannede kvitteringer. " +
                        "Kvaliteten og nøyaktigheten til bildene og beskrivelsene nedenfor avhenger av hvor presise beskrivelsene " +
                        "på kvitteringene er. " +
                        "Og ulike butikker kan gi samme produkt forskjellige navn.",
                Modifier
            )
        }
    }
}