package com.matprisguru.utility

import com.matprisguru.data.uploadFileToOCRSpace
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import org.w3c.files.File

data class PreprocessResponse(
    var isSuccessful: Boolean = false,
    var isValidReceipt: Boolean = false,
    var errorMessage: String = ""
)

suspend fun preProcessReceipt(file: File): PreprocessResponse{

    val responseString = uploadFileToOCRSpace(file)
    println("preProcess upload response: $responseString")

    val preprocessResult = PreprocessResponse()
    val isOCRSpaceError = checkIfOCRSpaceError(responseString)
    if(isOCRSpaceError != null){
        preprocessResult.errorMessage = isOCRSpaceError.message
    }
    else{
        preprocessResult.isSuccessful = true
        preprocessResult.isValidReceipt = checkIfValidReceipt(responseString)
    }

    return preprocessResult
}

@Serializable
data class ApiResponseStructure(val data: String)

@Serializable
data class ApiDataStructure(
    //case-sensitive stuff in here!
    val ParsedResults: List<ParsedResult>
    //...and more: "data":"    {	"ParsedResults\":[{\"TextOverlay\":{\"Lines\":[],\"HasOverlay\":false,\"Message\":\"Text overlay is not provided as it is not requested\"},\"TextOrientation\":\"0\",\"FileParseExitCode\":1,\"ParsedText\":\"12 : 44\\r\\nREMA 1000 RYENSVINGEN\\r\\n27. sep. kl. 18:47\\r\\nChocolate Chip Cookies 225G R\\r\\nR 225 G\\r\\nGarlic Sauce 220Ml Heinz\\r\\nHeinz 220 MI\\r\\nKokosmelk Tetra 250Ml Rema 1000\\r\\nRema 1000 250 MI\\r\\nKokosmelk Tetra 250MI Rema 1000\\r\\nRema 1000 250 MI\\r\\nTannkrem Max Fresh Cooling 75 MI\\r\\nCooling Crystals 75 MI\\r\\nTomatketchup 342G Heinz\\r\\n342 G\\r\\nVaniljevafler 150G R\\r\\nR 150 G\\r\\nSum\\r\\nBetalt\\r\\nKortbetaling\\r\\nHjem\\r\\nHandleturer\\r\\n29,90\\r\\n47,90\\r\\n17,00\\r\\n17,00\\r\\n31,50\\r\\n21,30\\r\\n19,90\\r\\n184,50\\r\\n184,50\\r\\nO\\r\\nProfil\\r\\n\",\"ErrorMessage\":\"\",\"ErrorDetails\":\"\"}],\"OCRExitCode\":1,\"IsErroredOnProcessing\":false,\"ProcessingTimeInMilliseconds\":\"500\",\"SearchablePDFURL\":\"Searchable PDF not generated as it was not requested.\"}"}
)

@Serializable
data class ParsedResult(
    val ParsedText: String
)

@Serializable
data class OCRSpaceErrorStructure(
    val message: String
)

fun checkIfValidReceipt(responseString: String): Boolean {

    var containsStoreName = false
    var containsText = false

    try{
        val json = Json {ignoreUnknownKeys = true}
        val responseObject = json.decodeFromString<ApiResponseStructure>(responseString)
        val dataObject = json.decodeFromString<ApiDataStructure>(responseObject.data)
        val parsedText = dataObject.ParsedResults[0].ParsedText
        val lines = parsedText.split("\r\n")
        println(lines)

        // Check for store names anywhere
        containsStoreName = lines.any { line ->
            GlobalVariables.listAllStoresShort.any { store ->
                store.lowercase() in line.lowercase()
            }
        }

        containsText = parsedText.length>30

    } catch(e: Throwable){
        println("some error happened: ${e.message}")
    }

    val isValidReceipt = containsText //TODO: containsStoreName
    return isValidReceipt
}

fun checkIfOCRSpaceError(responseString: String): OCRSpaceErrorStructure?{
    try {
        val json = Json {ignoreUnknownKeys = true}
        val responseObject = json.decodeFromString<OCRSpaceErrorStructure>(responseString)
        return responseObject
    }catch (e: Throwable){
        println("Looks like successful response. FyI: ${e.message}")
    }
    return null
}