package com.matprisguru.pages

import androidx.compose.runtime.*
import com.matprisguru.components.*
import com.matprisguru.components.widgets.GradientBox
import com.matprisguru.data.getAllProducts
import com.matprisguru.model.ApiListResponse
import com.matprisguru.model.Product
import com.matprisguru.utility.GlobalVariables
import com.matprisguru.utility.GlobalVariables.isMobile
import com.varabyte.kobweb.compose.css.Height
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.H1

val isAdminMode = false

@Page
@Composable
fun HomePage() {

    val context = rememberPageContext()

    var response by remember { mutableStateOf<ApiListResponse>(ApiListResponse.Loading) }

    val searchTextCommon = remember { mutableStateOf("") }

    LaunchedEffect(Unit){

        getAllProducts(
            onSuccess = {
                if(it is ApiListResponse.Success){
                    response = it
                }
            },
            onError = {
                println("Error")
                println(it.message.toString())
                response = ApiListResponse.Error(it.message.toString())
            }
        )


    }


    HeaderLayout(
        context = context
    ){
        GradientBox(
            contentAlignment = Alignment.Center,
            modifier = Modifier.fillMaxWidth()
        ) {
            Column(
                modifier = Modifier.fillMaxSize(90.percent).maxWidth(1000.px).padding(4.px),
                horizontalAlignment = Alignment.CenterHorizontally,

                //verticalArrangement = Arrangement.Top
            ) {

                H1(
                    attrs = {
                        style {
                            marginBottom(8.px)
                            marginTop(64.px)
                            marginBottom(24.px)
                        }
                    }
                ) {
                    SpanText(
                        "Sjekk priser på daglivarer",
                        modifier = Modifier.textAlign(TextAlign.Center)
                        //.textShadow(1.px,3.px,color=Color.red)
                    )
                }

                SpanText(
                    "Sammenlign priser fra Rema 1000, Kiwi " +
                            "og Extra og se hvor produktene dine er billigst.",
                    modifier = Modifier.lineHeight(1.5).fontSize(1.25.cssRem).textAlign(TextAlign.Center)
                        .margin(bottom = 28.px)
                )

                SearchElement(
                    searchTextCommon, fieldPlaceholder = "Søk et produkt...",
                    fieldHeight = searchFieldHeight * 1.5
                )



            }
        }

        Column(
            modifier = Modifier.fillMaxSize(90.percent).maxWidth(1000.px).padding(4.px),
            horizontalAlignment = Alignment.CenterHorizontally,

            //verticalArrangement = Arrangement.Top
        ) {


            if(response is ApiListResponse.Loading){
                Image("apples.gif", modifier = Modifier.align(Alignment.CenterHorizontally).margin(20.px))
            }
            else{
                MainTable(response, searchTextCommon)
            }


        }

    }


}


@Composable
fun MainTable(response: ApiListResponse, searchTextCommon: MutableState<String>) {

    val searchTextRema = remember { mutableStateOf("") }
    val searchTextKiwi = remember { mutableStateOf("") }
    val searchTextExtra = remember { mutableStateOf("") }

    val filteredProductRema = getFilteredProducts(response, searchTextCommon.value, searchTextRema.value, GlobalVariables.rema)
    val filteredProductKiwi = getFilteredProducts(response, searchTextCommon.value, searchTextKiwi.value, GlobalVariables.kiwi)
    val filteredProductExtra = getFilteredProducts(response, searchTextCommon.value, searchTextExtra.value, GlobalVariables.extra)



    StoreNameHeader()

    Row(
        modifier = Modifier
//            .fillMaxHeight()
            .fillMaxWidth()
            .margin(top=6.px),
        horizontalArrangement = Arrangement.Center
    ){
        StoreColumn(filteredProductRema)
        StoreColumn(filteredProductKiwi)
        StoreColumn(filteredProductExtra)
    }

}

fun getFilteredProducts(
    response: ApiListResponse,
    searchTextCommon: String,
    searchTextStore: String,
    storeName: String): List<Product>?{

    val maxNumberOfProductsPerColumn = if(isAdminMode) 99999 else 50

    return when (response) {
        is ApiListResponse.Success -> response.data.filter {
            val storeNamesMatch = (it.store.lowercase()==storeName.lowercase())
            if(searchTextStore=="" && searchTextCommon==""){
                storeNamesMatch
            }else if(searchTextStore!=""){
                storeNamesMatch &&
                        it.description.contains(searchTextStore, ignoreCase = true)
            }else{
                storeNamesMatch &&
                        it.description.contains(searchTextCommon, ignoreCase = true)
            }
        }.groupBy { it.price }.map { (price, products) -> products.first() }.take(maxNumberOfProductsPerColumn)

        else -> {
            println("Nothing to display $storeName")
            null
        }
    }
}

@Composable
fun StoreColumn(
    filteredProducts: List<Product>?
){
    Column(
        modifier = Modifier.fillMaxSize()
            .padding(leftRight = if(isMobile) 6.px else 24.px)
            //.border(1.px, LineStyle.Solid, Color.red)
        ,horizontalAlignment = Alignment.CenterHorizontally
    ){

        filteredProducts?.forEach { product ->
            ProductBox(product.description, product.price, product.date, product.imageUrl)
        }

    }
}

@Composable
fun StoreNameHeader(){
    Row(
        Modifier.fillMaxWidth().height(Height.MaxContent)
            .position(Position.Sticky)
            .top(headerHeight)
            .zIndex(1)
            .alignContent(org.jetbrains.compose.web.css.AlignContent.Center)
            .textAlign(TextAlign.Center)
            .backgroundColor(Color.white)
            .border(2.px, LineStyle.Solid, Color.white)
            /*
            .backdropFilter(
                blur(10.px)
            )

             */
        ,
        horizontalArrangement = Arrangement.SpaceEvenly,
        verticalAlignment = Alignment.CenterVertically
    ){
        StoreName("rema.svg")
        StoreName("kiwi.svg")
        StoreName("extra.svg")
    }
}

@Composable
fun StoreName(imageSrc: String){
    Box(
        modifier = Modifier
            .fillMaxWidth().height(40.px).border(2.px)
            .margin(bottom=15.px),
        contentAlignment = Alignment.Center
    ) {
        Image(
            imageSrc,
            modifier = Modifier
                .fillMaxHeight()  // Fill the entire box (both width and height)
                .objectFit(ObjectFit.Cover)  // Ensures the image covers the box while maintaining aspect ratio
        )
        //SpanText()
    }
}