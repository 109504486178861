package com.matprisguru.pages

import androidx.compose.runtime.*
import androidx.compose.web.events.SyntheticDragEvent
import com.matprisguru.components.DescriptionText
import com.matprisguru.components.HeaderLayout
import com.matprisguru.components.widgets.GradientBox
import com.matprisguru.data.addNickname
import com.matprisguru.data.getAllNicknames
import com.matprisguru.data.getAllWinningChances
import com.matprisguru.data.uploadToDrive
import com.matprisguru.model.*
import com.matprisguru.navigation.Screen
import com.matprisguru.utility.getCurrentTimestamp
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.multiple
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLInputElement
import org.w3c.files.File
import org.w3c.files.get
import kotlin.math.round

private val targetNbr = 1000

@Page
@Composable
fun ContributePage() {
    val context = rememberPageContext()

    var chancesResponse by remember { mutableStateOf<ApiChancesListResponse>(ApiChancesListResponse.Loading) }
    var nicknamesResponse by remember { mutableStateOf<ApiNicknameListResponse>(ApiNicknameListResponse.Loading) }

    LaunchedEffect(Unit){
        getAllWinningChances(
            onSuccess = {
                if (it is ApiChancesListResponse.Success) {
                    println("Success: ${it.data}")
                    chancesResponse = it
                }
            },
            onError = {
                println("Error: ${it.message}")
                chancesResponse = ApiChancesListResponse.Error(it.message.toString())
            }
        )
        getAllNicknames(
            onSuccess = {
                if (it is ApiNicknameListResponse.Success) {
                    println("Success: ${it.data}")
                    nicknamesResponse = it
                }
            },
            onError = {
                println("Error: ${it.message}")
                nicknamesResponse = ApiNicknameListResponse.Error(it.message.toString())
            }
        )
    }

    HeaderLayout(
        context = context
    ){
        var selectedFiles by remember { mutableStateOf<List<File>>(emptyList()) }
        var selectedFileNames by remember { mutableStateOf<List<String>>(emptyList()) }
        var isUploading by remember { mutableStateOf(false) }
        var showThanks by remember { mutableStateOf(false) }
        var fileInputRef = remember { mutableStateOf<HTMLInputElement?>(null) }
        var isDragging by mutableStateOf(false)
        var nicknameText = remember{ mutableStateOf("")}
        var emailText = remember{ mutableStateOf("")}

        LotteryPageTitle(chancesResponse=chancesResponse)

        Column(
            modifier = Modifier.fillMaxSize()
                .padding(top=20.px),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                modifier = Modifier.fillMaxWidth(70.percent)
            ) {

                RulesButton()

                LotteryInput(
                    inputText = nicknameText,
                    leadingIcon = { FaSignature() },
                    fieldPlaceholder = "Kallenavn/Nickname"
                )
                LotteryInput(
                    inputText = emailText,
                    leadingIcon = { FaPaperPlane() },
                    fieldPlaceholder = "E-post eller tlf.nr. (for Vipps)"
                )

                FileDragAndDropBox(
                    isDragging = isDragging,
                    onDragStart = { isDragging = true },
                    onDragEnd = { isDragging = false },
                    onFilesSelected = { files ->
                        selectedFiles = files
                        selectedFileNames = files.map { it.name }
                    },
                    fileInputRef = fileInputRef
                )





                //display the file names
                selectedFileNames.forEach { fileName ->
                    SpanText(
                        fileName, modifier = Modifier

                    )
                }

                if (isUploading) {
                    UploadInfoBox()
                } else if (showThanks) {

                    ThanksBox()

                    LaunchedEffect(Unit) {
                        delay(5 * 1000)
                        showThanks = false
                    }
                }

                UploadButtonBox(
                    selectedFiles = selectedFiles,
                    isUploading = isUploading,
                    onUploadingChange = { isUploading = it },
                    onShowThanksChange = { showThanks = it },
                    onFileReset = {
                        selectedFiles = emptyList()
                        selectedFileNames = emptyList()
                    },
                    nicknameText = nicknameText.value,
                    emailText = emailText.value,
                    nicknamesResponse = nicknamesResponse
                )

                PreviousWinners()

                WinningChances(chancesResponse=chancesResponse, nicknamesResponse=nicknamesResponse)

            }

        }
    }
}

@Composable
fun PreviousWinners(){
    Box(
        Modifier.fillMaxWidth()
        , contentAlignment = Alignment.Center
    ){
        Column(
            Modifier.fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally
        ){
            H3(
                attrs = {
                    style {
                        marginTop(32.px)
                        marginBottom(40.px)
                    }
                }
            ) { Text("Tidligere Lotteritrekkninger") }


            Box(
                Modifier
                    .fillMaxWidth()
                    .padding(12.px)
                    .border(1.px, LineStyle.Solid, Color.black)
                    .borderRadius(7.px)
                    .padding(8.px)
                    .cursor(Cursor.Pointer)
                    .onClick { window.open("https://www.youtube.com/@Matprisguru", "_blank") }
                , contentAlignment = Alignment.Center
            ){

                FaYoutube(
                    size = IconSize.X3,
                    modifier = Modifier
                        .color(Color.red)
                )
            }
        }

    }


}

@Composable
fun UploadInfoBox(text: String = "Laster opp..."){
    Box(
        modifier = Modifier
            .borderRadius(7.px)
            .height(60.px)
            .margin(20.px)
            .boxShadow(blurRadius = 4.px, spreadRadius = 1.px)
            .border(1.px)
            .padding(16.px)
    ){
        Row(
            modifier = Modifier.fillMaxSize(),
            verticalAlignment = Alignment.CenterVertically
        ){
            SpanText(text, modifier = Modifier.fillMaxWidth())
        }

    }
}


@Composable
fun ThanksBox(){
    Box(
        modifier = Modifier
            .borderRadius(7.px)
            .height(60.px)
            .margin(20.px)
            .boxShadow(blurRadius = 4.px, spreadRadius = 1.px)
            .border(1.px)
            .padding(6.px)
    ){
        Row(
            modifier = Modifier.fillMaxSize().alignContent(AlignContent.Center),
            verticalAlignment = Alignment.CenterVertically
        ){
            FaCheck(modifier = Modifier.margin(right = 20.px))
            SpanText("Takk for ditt bidrag", modifier = Modifier.fillMaxWidth())
        }

    }
}

@Composable
fun LotteryPageTitle(chancesResponse: ApiChancesListResponse){

    val nbrOutstandingProducts = if(chancesResponse is ApiChancesListResponse.Success)
        (targetNbr - chancesResponse.data.sumOf { it.products }) else null

    GradientBox(
        contentAlignment = Alignment.Center,
        modifier = Modifier.fillMaxWidth()
    ) {
        Column(
            modifier = Modifier.fillMaxSize()
                .padding(top = 20.px),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            H2(
                attrs = {
                    style {
                        marginTop(32.px)
                        marginBottom(40.px)
                    }
                }
            ) { Text("Last opp og vinn!") }

            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                modifier = Modifier.fillMaxWidth(70.percent)
            ) {
                DescriptionText(
                    "Ønsker du se også dine egne produkter på Matprisguru? " +
                            "Nettsiden lever av opplastede kvitteringer. " +
                            "Last opp din Rema, Kiwi eller Extra kvittering og bidra til " +
                            "projektet."
                )

                H3(
                    attrs = {
                        style {
                            marginTop(32.px)
                            marginBottom(10.px)
                        }
                    }
                ) { Text("Jackpot: 1000 kr") }

                H5(
                    attrs = {
                        style {
                            marginTop(32.px)
                            marginBottom(40.px)
                        }
                    }
                ) {
                    if(nbrOutstandingProducts != null){
                        Text("Antall unike produkter til belønning: ${maxOf(0, nbrOutstandingProducts)}")
                    }

                }
            }
        }
    }
}


@Composable
fun FileDragAndDropBox(
    isDragging: Boolean,
    onDragStart: () -> Unit,
    onDragEnd: () -> Unit,
    onFilesSelected: (List<File>) -> Unit, // Updated to handle multiple files
    fileInputRef: MutableState<HTMLInputElement?>
) {
    val dragBoxModifier0 = Modifier.fillMaxWidth()
        .borderRadius(7.px)
        .padding(20.px)
        .border(1.px, LineStyle.Dashed)
        .cursor(Cursor.Pointer)
        .margin(top = 20.px)
        .onClick {
            fileInputRef.value?.click()
        }
        .onDragOver { event: SyntheticDragEvent ->
            event.preventDefault() // Prevent default behavior to allow drop
            onDragStart()
        }
        .onDragLeave {
            onDragEnd()
        }
        .onDrop { event: SyntheticDragEvent ->
            event.preventDefault()
            onDragEnd()

            // Handle the dropped files
            val droppedFiles = event.dataTransfer?.files
            if (droppedFiles != null) {
                val fileList = (0 until droppedFiles.length).mapNotNull { index ->
                    droppedFiles.item(index)
                }
                onFilesSelected(fileList) // Pass the list of files
            }
        }

    val dragBoxModifier = if (isDragging) {
        dragBoxModifier0.backgroundColor(Color.lightgray)
    } else {
        dragBoxModifier0
    }

    Box(
        modifier = dragBoxModifier, contentAlignment = Alignment.Center
    ) {
        Input(
            type = InputType.File,
            attrs = {
                style {
                    display(DisplayStyle.None) // Hide the input
                }
                multiple() // Allow selecting multiple files
                onInput {
                    val selectedFiles = it.target.files
                    if (selectedFiles != null) {
                        val fileList = (0 until selectedFiles.length).mapNotNull { index ->
                            selectedFiles[index]
                        }
                        onFilesSelected(fileList) // Pass the list of files
                    }
                }
                ref { inputElement ->
                    fileInputRef.value = inputElement
                    onDispose { fileInputRef.value = null }
                }
            }
        )
        Row(
            modifier = Modifier.alignContent(AlignContent.Center),
            verticalAlignment = Alignment.CenterVertically
        ) {
            FaUpload(
                modifier = Modifier
                    .margin(20.px), size = IconSize.XXL
            )
            SpanText(
                "Drag & Drop",
                modifier = Modifier
            )
        }
    }
}




@Composable
fun LotteryInput(
    inputText: MutableState<String>,
    leadingIcon: @Composable () -> Unit,
    fieldPlaceholder: String = "Kallenavn/Nickname"
){
    Box(
        Modifier
            .fillMaxWidth()
            .height(40.px)
            .border(1.px, LineStyle.Solid, Color.black)
            .borderRadius(7.px)
            .padding(8.px)
            .margin(bottom = 8.px)
    ) {
        Row(
            Modifier.fillMaxSize(),
            verticalAlignment = Alignment.CenterVertically,
        ){
            leadingIcon()
            Input(
                type = InputType.Text,
                attrs = {
                    value(inputText.value)
                    onInput { event -> inputText.value = event.value }
                    placeholder(fieldPlaceholder)
                    style {
                        padding(8.px)                    // Add padding to make it a bit larger
                        fontSize(16.px)
                        height(Height.Inherit)
                        width(Width.Inherit)
                        border(0.px)
                        outline("none")
                        borderRadius(7.px)
                    }
                }
            )
        }

    }

}

@Composable
fun UploadButtonBox(
    selectedFiles: List<File>,
    isUploading: Boolean,
    onUploadingChange: (Boolean) -> Unit,
    onShowThanksChange: (Boolean) -> Unit,
    onFileReset: () -> Unit,
    nicknameText: String,
    emailText: String,
    nicknamesResponse: ApiNicknameListResponse
) {

    val scope = rememberCoroutineScope()

    Box(
        modifier = Modifier.fillMaxWidth()
            .padding(20.px),
        contentAlignment = Alignment.Center
    ) {
        Button(
            attrs = {
                style {
                    height(60.px)
                    width(240.px)
                    cursor(Cursor.Pointer.toString())
                }
                onClick {
                    if (
                        !isUploading &&
                        (nicknamesResponse is ApiNicknameListResponse.Success)
                    ){
                        if (selectedFiles.isNotEmpty()) {

                            if(!isValidNickname(nicknameText)){
                                window.alert("Kallenavn ugyldig")
                                return@onClick
                            }

                            val existingNicknameId = nicknamesResponse.data.firstOrNull{ it.nickname==nicknameText }?._id

                            if(existingNicknameId != null && existingNicknameId != emailText){
                                window.alert("Kallenavn allerede brukt i forbindelse med et annet tel.nr. " +
                                        "eller en annen epost")
                                return@onClick
                            }

                            if( !isValidEmailOrNumber(emailText) ){
                                window.alert("Tel.nr eller epost ugyldig")
                                return@onClick
                            }

                            val allowedFileTypes = listOf("image/png", "image/jpeg", "application/pdf")
                            val maxFileSizeInBytes = 5 * 1024 * 1024 // 5 MB


                            uploadNickname(scope, emailText, nicknameText)

                            selectedFiles.forEach { selectedFile ->

                                val fileType = selectedFile.type
                                val fileSize = selectedFile.size.toInt()
                                val selectedFileName = selectedFile.name

                                if (fileType !in allowedFileTypes) {
                                    window.alert("Ugyldig filtype! Vennligst bare last opp PNG-, JPEG- eller PDF-filer.")
                                    return@onClick
                                }

                                if (fileSize > maxFileSizeInBytes) {
                                    window.alert("En av filene er for stor! Maksimal filstørrelse er 5 MB.")
                                    return@onClick
                                }

                                uploadFileToDrive(
                                    scope=scope,
                                    onUploadingChange=onUploadingChange,
                                    selectedFileName=selectedFileName,
                                    onShowThanksChange=onShowThanksChange,
                                    onFileReset=onFileReset,
                                    nicknameText=nicknameText,
                                    emailText=emailText,
                                    selectedFile=selectedFile
                                )

                            }

                        } else {
                            window.alert("Ingen fil valgt!")
                        }
                    }
                }
            }
        ) {
            Text("Last opp")
        }
    }
}

fun uploadNickname(scope: CoroutineScope, emailText: String, nicknameText: String){
    val nickname = Nickname(_id = emailText, nickname = nicknameText)
    scope.launch {
        addNickname(
            nickname,
            onError = { println("Error: ${it.message}") }
        )
    }

}

fun uploadFileToDrive(
    scope: CoroutineScope,
    onUploadingChange: (Boolean) -> Unit,
    selectedFileName: String,
    onShowThanksChange: (Boolean) -> Unit,
    onFileReset: () -> Unit,
    nicknameText: String,
    emailText: String,
    selectedFile: File
){
    scope.launch {
        onUploadingChange(true)
        try {

            val ts = getCurrentTimestamp()
            val fileExtension =
                selectedFileName.substringAfterLast(".", "") // Extracts the file extension
            val driveFileName = "${ts}_${nicknameText}_${emailText}.$fileExtension"


            val responseString = uploadToDrive(selectedFile, driveFileName)
            println("upload to drive: $responseString")
            onShowThanksChange(true)
        } catch (e: Exception) {
            window.alert("Noe gikk galt.")
        }
        onUploadingChange(false)
        onFileReset()
    }
}

@Composable
fun RulesButton(){
    val context = rememberPageContext()
    var mouseIsOver by remember { mutableStateOf(false) }
    Button(
        attrs = {
            style {
                backgroundColor(if(mouseIsOver) Color.lightgreen else Color.green)
                //color(Color.white)
                marginBottom(32.px)
                padding(12.px)
                border(2.px, LineStyle.Solid, Color.green)
                borderRadius(7.px)
                minWidth(150.px)
                cursor(Cursor.Pointer.toString())
            }
            onClick { context.router.navigateTo(Screen.LotteryRules.route) }
            onMouseOver { mouseIsOver = true }
            onMouseOut { mouseIsOver = false }
        }
    ) {
        SpanText("Regler")
    }
}

@Composable
fun WinningChances(
    chancesResponse: ApiChancesListResponse,
    nicknamesResponse: ApiNicknameListResponse
){


    H3(
        attrs = {
            style {
                marginTop(32.px)
                marginBottom(40.px)
            }
        }
    ) { Text("Vinnersjanser") }





    var chances: List<WinningChance>? = null
    var nicknames: List<Nickname>? = null

    if(
        (chancesResponse is ApiChancesListResponse.Loading) ||
        (nicknamesResponse is ApiNicknameListResponse.Loading)
        ){
        Column(){
            Image("apples.gif", modifier = Modifier.align(Alignment.CenterHorizontally).margin(20.px))
        }
    }
    else{
        if (
            (chancesResponse is ApiChancesListResponse.Success) &&
            (nicknamesResponse is ApiNicknameListResponse.Success)
        ){
            chances = chancesResponse.data
            nicknames = nicknamesResponse.data
        } else {
            println("Nothing to display")
        }

        val chancesWithNicknames = combineLists(chances, nicknames)

        val commonModifier = Modifier
            .margin(right = 4.px).fillMaxWidth(33.percent)
            .alignContent(AlignContent.Center)

        Row(
            Modifier.fillMaxWidth().padding(12.px).margin(4.px),
            horizontalArrangement = Arrangement.SpaceEvenly
        ){
            SpanText("Kallenavn", commonModifier.margin(leftRight = 4.px).fontWeight(FontWeight.SemiBold))
            SpanText("Nye unike Produkter", commonModifier.fontWeight(FontWeight.SemiBold))
            SpanText("Vinnersjanse", commonModifier.margin(leftRight = 4.px).fontWeight(FontWeight.SemiBold))
        }

        chancesWithNicknames.forEach {
            Row(
                Modifier.fillMaxWidth().padding(12.px).margin(4.px),
                horizontalArrangement = Arrangement.SpaceEvenly
            ){
                val percentages = round(it.products.toDouble() / targetNbr * 100 * 10) / 10
                SpanText(it.nickname, commonModifier.margin(leftRight = 4.px))
                SpanText(it.products.toString(), commonModifier)
                SpanText("$percentages%", commonModifier.margin(leftRight = 4.px))
            }

        }
    }
}

fun combineLists(
    chances: List<WinningChance>?,
    nicknames: List<Nickname>?
): List<CombinedData> {
    // Safely handle null lists by returning an empty list if either is null
    if (chances == null || nicknames == null) return emptyList()

    // Create a map from nicknames for quick lookup
    val nicknameMap = nicknames.associateBy { it._id }

    // Map the chances list to the combined data
    return chances.mapNotNull { chance ->
        nicknameMap[chance._id]?.let { nickname ->
            CombinedData(
                _id = chance._id,
                nickname = nickname.nickname,
                products = chance.products
            )
        }
    }
}

// email or number is used as ID in mongo tables
fun isValidEmailOrNumber(emailText: String): Boolean {
    // Regex for a valid email format
    val emailRegex = Regex("^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$")
    // Regex for numbers only
    val numberRegex = Regex("^\\d+$")
    // Check if the string matches either pattern
    return emailRegex.matches(emailText) || numberRegex.matches(emailText)
}

fun isValidNickname(nicknameText: String): Boolean{
    return Regex("^[a-zA-Z0-9øåæØÅÆ()]+(?: [a-zA-Z0-9øåæØÅÆ()]+)*$").matches(nicknameText)
}