package com.matprisguru.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import com.matprisguru.data.deleteProduct
import com.matprisguru.pages.isAdminMode
import com.matprisguru.utility.GlobalVariables.isMobile
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Text

val boxHeight = 280.px
val boxPadding = if(isMobile) 6.px else 26.px
val innerBoxHeight = (boxHeight-2*boxPadding)
val descriptionBoxHeight = innerBoxHeight/5
val imageBoxHeight = innerBoxHeight*0.6
private val isDebugMode = false

@Composable
fun ProductBox(
    description: String,
    price: Double,
    dateString: String,
    imageUrl: String
){
    val isDetailMode = remember { mutableStateOf(false) }

    val boxModifier = Modifier
        .borderRadius(12.px)
        .border(1.px, LineStyle.Solid)
        .fillMaxWidth()
        .margin(topBottom = if(isMobile) 6.px else 24.px)
        .padding(boxPadding)
        .cursor(Cursor.Pointer)
        .onClick {
            isDetailMode.value = !isDetailMode.value
        }

    Box(
        modifier = if(isDetailMode.value) boxModifier.minHeight(boxHeight) else boxModifier.height(boxHeight)
    ) {
        //DetailedBoxContent(description, price, dateString, descriptionColor)
        BoxContent(description, price, dateString, isMobile, imageUrl, isDetailMode.value)
    }
}

fun Modifier.maxLines(lines: Int) = this
    .textOverflow(TextOverflow.Ellipsis)
    .overflow(Overflow.Hidden)
    .display(DisplayStyle("-webkit-box"))
    .styleModifier {
        property("-webkit-line-clamp", "$lines") // Limits to 'lines' number of lines
        property("-webkit-box-orient", "vertical") // Clamps vertically
        property("line-height", "1.2") // Controls the line height, adjust if needed
        property("white-space", "normal") // Ensure text wraps normally within the box
        property("word-break", "normal") // First preference: break words at whitespace
        property("overflow-wrap", "break-word") // Fallback: break long words when necessary
        property("hyphens", "auto") // Optional: allows hyphenation for longer words
        property("max-height", "calc(1.2em * $lines)") // Prevents more than 'lines' lines visually
    }
fun cLowercase(string: String): String { //capital lowercase
    val stringList = string.split(" ")

    val modifiedStringList = stringList
        .map { it.take(1).uppercase() + it.drop(1).lowercase() }

    val resultString = modifiedStringList.joinToString(" ")

    return resultString
}

@Composable
fun BoxContent(
    description: String, price: Double, dateString: String, isMobile: Boolean, imageUrl: String,
    isDetailBox: Boolean = false
){

    val scope = rememberCoroutineScope()

    Column(
        modifier = Modifier.fillMaxSize()
            .thenIf(isDebugMode, Modifier.border(1.px, LineStyle.Solid, Color.green))
        , verticalArrangement = Arrangement.SpaceBetween
        , horizontalAlignment = Alignment.CenterHorizontally
    ) {

        Box(
            Modifier.fillMaxWidth().height(imageBoxHeight)
                .thenIf(isDebugMode, Modifier.border(1.px, LineStyle.Solid, Color.violet))
            , contentAlignment = Alignment.Center
        ){
            Image(
                imageUrl
                ,Modifier.fillMaxSize().styleModifier {
                    property("object-fit", "contain") // Maintains aspect ratio by fitting the image within the box
                }
            )
        }



        val boxHeightModifier = if(isDetailBox)
            Modifier.fillMaxWidth().minHeight(descriptionBoxHeight) else
                Modifier.fillMaxWidth().height(descriptionBoxHeight)
        Box(
            modifier = boxHeightModifier
                .thenIf(isDebugMode, Modifier.border(1.px, LineStyle.Solid, Color.red))
            , contentAlignment = Alignment.Center
        ){
            val commonModifier = Modifier
                .fillMaxWidth()
                .fontSize(if(isMobile) FontSize.Small else FontSize.Medium)
                .fontWeight(FontWeight.SemiBold)
                .textAlign(TextAlign.Center)
                .attrsModifier { attr("translate", "no") }
                .thenIf(isDebugMode, Modifier.border(1.px, LineStyle.Solid, Color.blue))

            val lineBreakModifier = if(isDetailBox)
                commonModifier
                    .whiteSpace(WhiteSpace.Normal)
                    .wordBreak(WordBreak.Normal)
                    .overflowWrap(OverflowWrap.BreakWord)
            else
                commonModifier.maxLines(if(isMobile) 3 else 2)

            SpanText(cLowercase(description), lineBreakModifier)
        }



        SpanText("$price kr",
            modifier = Modifier
                .margin(4.px).fontStyle(FontStyle.Italic)
                .thenIf(isDebugMode, Modifier.border(1.px, LineStyle.Dashed, Color.red))

        )


        Box(
            modifier = Modifier
                .width(80.px)
                .align(Alignment.CenterHorizontally)
            , contentAlignment = Alignment.Center
        ){
            SpanText(formatDate(dateString),
                modifier = Modifier
                    .fillMaxSize()
                    .textAlign(TextAlign.Center)
                    .alignContent(com.varabyte.kobweb.compose.css.AlignContent.Center)
                    .padding(2.px)
                    .fontSize(FontSize.XSmall)
                    .borderTop(1.px, LineStyle.Dashed, color=Color.black)
                    .thenIf(isDebugMode, Modifier.border(1.px, LineStyle.Solid, Color.blue))
            )
        }

        if(isAdminMode){
            Button(
                onClick = {
                    scope.launch {
                        deleteProduct(description)
                    }

                }
            ){
               Text("Delete")
            }
        }
    }

}
