package com.matprisguru.model

import kotlinx.serialization.Serializable

@Serializable
data class FileModel(
    val fileByteArray: ByteArray,
    val fileName: String
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as FileModel

        if (!fileByteArray.contentEquals(other.fileByteArray)) return false
        if (fileName != other.fileName) return false

        return true
    }

    override fun hashCode(): Int {
        var result = fileByteArray.contentHashCode()
        result = 31 * result + fileName.hashCode()
        return result
    }
}